<template>
  <div class="app-list-container">
    <div class="header">
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="12">
            <el-input v-model="app_id" placeholder="输入ID"></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="12">
            <el-input v-model="app_name" placeholder="输入应用名称"></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
      <span class="label">状态：</span>
      <el-select v-model="status" @change="onChangeStatus">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <el-table :data="applist.items" border stripe>
      <el-table-column label="应用图标">
        <template slot-scope="scope">
          <img :src="scope.row.app_icon" width="80" />
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="400" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="showDetail(scope.row)">
            查看详情
          </el-button>
          <el-button
            v-if="+scope.row.status !== 1"
            size="mini"
            type="primary"
            @click="update(scope.row, 1)"
          >
            通过
          </el-button>
          <el-button
            v-if="+scope.row.status !== -1"
            size="mini"
            type="info"
            @click="update(scope.row, -1)"
          >
            不通过
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30, 10, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="applist.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      title="应用详情"
      :visible.sync="detailDialog.visible"
      width="30%"
      @close="onClose"
    >
      <el-form
        disabled
        :model="detailDialog.form"
        ref="form"
        label-width="120px"
      >
        <el-form-item label="应用名称">
          <el-input v-model="detailDialog.form.app_name"></el-input>
        </el-form-item>
        <el-form-item label="应用图标">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            disabled
            list-type="picture-card"
            :file-list="appIconList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="应用简介">
          <el-input
            v-model="detailDialog.form.app_info"
            type="textarea"
            maxlength="500"
            minlength="5"
            :autosize="{ minRows: 3 }"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="应用截图">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            disabled
            list-type="picture-card"
            :file-list="appImgList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="应用分类">
          <el-select
            v-model="detailDialog.form.app_class_id"
            placeholder="请选择应用分类"
          >
            <el-option label="区块链浏览器" :value="1"></el-option>
            <el-option label="社交" :value="2"></el-option>
            <el-option label="新闻内容" :value="3"></el-option>
            <el-option label="交易所" :value="4"></el-option>
            <el-option label="金融服务" :value="5"></el-option>
            <el-option label="游戏娱乐" :value="6"></el-option>
            <el-option label="储存上链" :value="7"></el-option>
            <el-option label="搜索引擎" :value="8"></el-option>
            <el-option label="商家与服务" :value="9"></el-option>
            <el-option label="钱包" :value="10"></el-option>
            <el-option label="其他" :value="11"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用回调域名">
          <el-input v-model="detailDialog.form.merchant_domain"></el-input>
        </el-form-item>
        <el-form-item label="BSV收款地址">
          <el-input v-model="detailDialog.form.receive_address_bsv"></el-input>
        </el-form-item>
        <el-form-item label="BTC收款地址">
          <el-input v-model="detailDialog.form.receive_address_btc"></el-input>
        </el-form-item>
        <el-form-item label="ETH收款地址">
          <el-input v-model="detailDialog.form.receive_address_eth"></el-input>
        </el-form-item>
        <el-form-item label="应用语言" prop="app_lang">
          <el-select
            v-model="appLang"
            multiple
            clearable
            size="medium"
            placeholder="请选择语言"
          >
            <el-option
              v-for="item in langOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="网页型应用">
          <el-input v-model="detailDialog.form.get_web_url"></el-input>
        </el-form-item>
        <el-form-item label="iOS应用">
          <el-input v-model="detailDialog.form.get_ios_url"></el-input>
        </el-form-item>
        <el-form-item label="Android商店应用">
          <el-input v-model="detailDialog.form.get_android_url"></el-input>
        </el-form-item>
        <el-form-item label="Android APK包">
          <el-input v-model="detailDialog.form.get_apk_url"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="detailDialog.visible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { updateApplicationHistory } from "@/api/developer";

const LANG_MAP = {
  zh: "中文",
  en: "英文",
  ja: "日文",
};

export default {
  data() {
    return {
      columns: [
        { id: "id", label: "ID" },
        { id: "merchant_id", label: "商家ID" },
        { id: "app_id", label: "APP ID" },
        { id: "app_name", label: "应用名称" },
        { id: "app_info", label: "应用简介" },
        {
          id: "app_lang",
          label: "应用语言",
          formatter: (row) => {
            if (!row.app_lang || row.app_lang == "[]") {
              return "";
            }
            const list = JSON.parse(row.app_lang);
            return list.map((lang) => LANG_MAP[lang]).join("，");
          },
        },
        {
          id: "status",
          label: "状态",
          formatter: (row) => {
            switch (+row.status) {
              case 0:
                return <el-tag>待审核</el-tag>;
              case 1:
                return <el-tag type="success">审核通过</el-tag>;
              case -1:
                return <el-tag type="warning">审核不通过</el-tag>;
              case -2:
                return <el-tag type="danger">冻结</el-tag>;
              case -3:
                return <el-tag type="info">删除</el-tag>;
              default:
                return row.status;
            }
          },
        },
        {
          id: "publish",
          label: "应用墙",
          formatter: (row) => {
            if (+row.publish === 1) {
              return <el-tag type="success">申请发布</el-tag>;
            }
            return <el-tag type="info">未申请发布</el-tag>;
          },
        },
      ],
      langOptions: [
        { value: "zh", label: "中文" },
        { value: "en", label: "英文" },
        { value: "ja", label: "日文" },
      ],
      status: 0,
      app_id: "",
      app_name: "",
      statusOptions: [
        { label: "待审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "审核失败", value: -1 },
        { label: "冻结", value: -2 },
        { label: "全部", value: "" },
      ],
      audit: false,
      detailDialog: {
        visible: false,
        form: {},
      },
      currentPage: 1,
      pageSize: 30,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["applist"]),
    appIconList() {
      if (this.detailDialog.form.app_icon) {
        return [
          {
            name: "app_icon",
            url: this.detailDialog.form.app_icon,
          },
        ];
      }
      return [];
    },
    appLang() {
      let langs = [];
      if (!this.detailDialog.form.app_lang) {
        return langs;
      }
      try {
        langs = JSON.parse(this.detailDialog.form.app_lang);
      } catch (error) {
        console.log(error);
      }
      return langs;
    },
    appImgList() {
      let list = [];
      if (Array.isArray(this.detailDialog.form.app_img)) {
        list = this.detailDialog.form.app_img;
      } else {
        try {
          if (this.detailDialog.form.app_img === undefined) {
            console.log(this.detailDialog.form);
          }
          list = JSON.parse(this.detailDialog.form.app_img || "[]");
        } catch (error) {
          console.log(error);
        }
      }

      return list.map((url, index) => ({
        url,
        name: "app_img_" + index,
      }));
    },
  },
  methods: {
    fetchData() {
      let query = {
        status: this.status,
        page: this.currentPage,
        page_size: this.pageSize,
        app_id: this.app_id,
        app_name: this.app_name,
      };
      if (this.status === "") {
        delete query.status;
      }
      if (this.app_id === "") {
        delete query.app_id;
      }
      if (this.app_name === "") {
        delete query.app_name;
      }
      this.$store.dispatch("applist/getAppHistoryList", query);
    },
    showDetail(row) {
      this.detailDialog = {
        visible: true,
        form: row,
      };
    },
    onChangeStatus(val) {
      this.fetchData();
    },
    onClose() {
      this.detailDialog.form = {};
    },
    async update(row, status) {
      // 审核不通过，需要输入原因
      if (status === -1) {
        this.$prompt("请输入审核不通过原因", `应用【${row.app_name}】`, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            if (!value) {
              this.$message({
                message: "请输入审核不通过原因",
                duration: 3000,
                type: "error",
              });
              return;
            }
            updateApplicationHistory(row.id, {
              status,
              rejected_reason: value,
            }).then((data) => {
              console.log(data.data);
              if (+data.data.code) {
                this.$message({
                  message: data.data.msg,
                  duration: 3000,
                  type: "error",
                });
              } else {
                this.$message({
                  message: data.data.msg,
                  duration: 3000,
                  type: "success",
                });
                this.sizeChange();
              }
            });
          })
          .catch((err) => console.log(err));
        return;
      }
      // 其他操作
      let optMsg = "操作";
      switch (status) {
        case 1:
          optMsg = "审核通过";
          break;
        case -1:
          optMsg = "审核不通过";
          break;
        case -2:
          optMsg = "冻结";
          break;
        case -3:
          optMsg = "删除";
          break;
      }
      this.$confirm(`${optMsg}【${row.app_name}】, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateApplicationHistory(row.id, { status }).then((data) => {
            console.log(data.data);
            if (+data.data.code) {
              this.$message({
                message: data.data.msg,
                duration: 3000,
                type: "error",
              });
            } else {
              this.$message({
                message: data.data.msg,
                duration: 3000,
                type: "success",
              });
              this.sizeChange();
            }
          });
        })
        .catch((err) => console.log(err));
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    search() {
      this.currentPage = 1;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.app-list-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
